<template>
  <div>
    <!-- <div class="logo text-center">
      <img :src="require('@/assets/images/logo-ddh-blue.png')" alt class />
    </div> -->

    <div class="close-student" v-if="studentCredential.isTeacher">
      <router-link :to="{ name: 'grade', params: { grade: grade } }">
        <!-- <i class="fa fa-chevron-left"></i> -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="36"
          viewBox="0 0 36 36"
        >
          <path
            fill="#000"
            fill-rule="evenodd"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.6"
            d="M15.123 11.294a1.004 1.004 0 0 1 0 1.42l-4.282 4.282h17.156a1.003 1.003 0 1 1 0 2.007H10.84l4.282 4.284a1.004 1.004 0 0 1-1.419 1.42L7 18l6.705-6.706a1.003 1.003 0 0 1 1.418 0z"
          />
        </svg>
        <span>VOLTAR</span>
      </router-link>
    </div>

    <div class="form-student">
      <div class="va-row text-left">
        <div class="flex xs12">
          <h3 class="text-left student-name">
            É rapidinho, comece pelo nome da criança:
            <span>{{ studentCredential.user.name }}</span>
          </h3>
        </div>
      </div>

      <!-- <h5>Faça seu login para acessar a área do aluno</h5> -->
      <div class="va-row">
        <div class="flex xs12 flag-relative">
          <div v-if="multiple_products != false">
            <select-normal
              :items="multiple_products"
              :value="''"
              label="Selecione um produto"
              v-validate="'required'"
              name="product"
              v-model="productId"
              required="true"
              :error="errors.first('product')"
              @change="changeProduct($event)"
            />
          </div>

          <div class="input-group">
            <input-normal
              type="text"
              label="Nome da Criança"
              name="username"
              v-validate="'required'"
              :maxlength="20"
              max-lenth="20"
              :disabled="false"
              :data-vv-as="'nome'"
              :value="name"
              :error="errors.first('username')"
              @input="name = $event.target.value"
            />

            <small>&nbsp; Indicado usar apenas o primeiro nome</small>
          </div>

          <div
            class="va-row"
            v-if="
              studentCredential.main_product.brand.name.includes('Aniversário')
            "
          >
            <div class="flex md3 no-padding-left">
              <select-normal
                :items="days"
                :value="selectedDay"
                label="Dia"
                v-validate="'required'"
                name="dia"
                v-model="selectedDay"
                required="true"
                @change="changeDay($event)"
              />
            </div>

            <div class="flex md9 no-padding-right">
              <select-normal
                :items="months"
                :value="selectedMonth"
                label="Mês"
                v-validate="'required'"
                name="mes"
                v-model="selectedMonth"
                required="true"
                @change="changeMonth($event)"
              />
            </div>

            <small>&nbsp; Preencha o dia o mês de nascimento da criança.</small>
          </div>
        </div>

        <div class="flex xs12 text-left">
          <GenderSelect
            name="gender"
            :label="'A criança é'"
            labelColor="#68596e"
            :boyId="'M'"
            :girlId="'F'"
            :activeId="gender"
            :error="errors.first('gender')"
            :typeLanguage="'pt'"
            v-on:update="changeGender"
          />
        </div>

        <div class="d-flex align--center justify--space-between flex xs12">
          <button class="btn btn-primary" type="submit" @click="sendChild">
            CONTINUAR
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import { mapGetters } from "vuex";
import { months, maxAgeAllowed, monthsHelper } from "./utils.js";
const axios = require("axios");

export default {
  name: "login-user",
  computed: {
    ...mapGetters({
      studentCredential: "studentCredential",
      // ...
    }),
  },

  props: ["grade"],

  data() {
    const days = [];
    const years = [];
    const currentYear = new Date().getFullYear();

    for (let day = 1; day <= 31; day++) days.push({ key: day, value: day });
    for (let i = 0; i < maxAgeAllowed; i++) {
      years.push({ key: currentYear - i, value: currentYear - i });
    }

    years.push({ key: "outro", value: "Outro" });

    return {
      name: "",

      // New gender

      genders: null,
      gender: "",
      //genderType: "M",

      showPopUp: true,
      multiple_products: false,
      productId: null,
      product: null,

      days: days,
      months: months,
      years: years,

      selectedDay: 0,
      selectedMonth: 0,
    };
  },
  async mounted() {
    const self = this;

    self.product = self.studentCredential.main_product;
    self.name = this.studentCredential.user.custom_name;
    self.gender = self.studentCredential.user.gender;

    if (
      this.studentCredential.multiple_products &&
      this.studentCredential.multiple_products.length > 0
    ) {
      this.multiple_products =
        this.studentCredential.multiple_products != false
          ? this.studentCredential.multiple_products.map((i) => {
              return { value: i.brand.name + " - " + i.name, key: i.id };
            }, [])
          : false;

      this.gender = self.studentCredential.user.gender;
      // this.studentCredential.multiple_products.forEach((element) => {
      //   if (element.id == this.studentCredential.main_product.id) {
      //     self.genders = element.types;
      //     //self.setGender();
      //   }

      //   // Detectar string ou número para pré setar o genero
      // });
    }

    // É uma edição?
    if (this.studentCredential.isUpdate == true) {
      this.productId = this.studentCredential.main_product.id;
    }

    if (this.studentCredential.uuid) {
      await this.getCustomCharacter();
    }

    if (
      this.studentCredential.main_product.custom_numero &&
      this.studentCredential.main_product.custom_mes
    ) {
      this.selectedDay = parseInt(
        this.studentCredential.main_product.custom_numero
      );
      this.selectedMonth = parseInt(
        this.studentCredential.main_product.custom_mes
      );
    }
  },
  methods: {
    // setGender() {
    //   const self = this;

    //   console.log(self.genders);

    //   if (typeof this.studentCredential.user.gender === "string") {
    //     self.gender = self.genders[this.studentCredential.user.gender];
    //     self.genderType = this.studentCredential.user.gender;
    //   } else {
    //     self.gender = this.studentCredential.user.gender;
    //     self.genderType = Object.keys(self.genders).find(
    //       (key) => self.genders[key] === self.gender
    //     );

    //     console.log(self.genderType);
    //   }
    // },

    async getCustomCharacter() {
      const self = this;

      var data = await axios
        .get(
          "https://builder.dentrodahistoria.com.br/api/customs_characters/" +
            self.studentCredential.uuid
        )
        .then(function (response) {
          if (response.data.custom.user_data.properties["mes"]) {
            self.months.forEach((element) => {
              if (
                element.name ==
                response.data.custom.user_data.properties["mes"].value
              ) {
                self.$store.commit(
                  "UPDATE_MAIN_PRODUCT_CUSTOM_MES",
                  element.key
                );
              }
            });
          }

          if (response.data.custom.user_data.properties["numero"]) {
            self.$store.commit(
              "UPDATE_MAIN_PRODUCT_CUSTOM_NUMERO",
              parseInt(
                response.data.custom.user_data.properties["numero"].value
              )
            );
          }
        })
        .catch(function (error) {
          console.log("Erro ao resgatar UUID");
        });

      return data;
    },

    async updateCustomCharacter() {
      const self = this;

      var custom_character = {
        character_id: this.studentCredential.main_product.character_id,
        product_id: this.studentCredential.main_product.builder_id,
        type_id:
          this.studentCredential.main_product.types[
            this.studentCredential.user.gender
          ],
        user_data: {
          type: this.studentCredential.main_product.types[
            this.studentCredential.user.gender
          ],
          properties: {
            nome: {
              code: "nome",
              property_id: 24,
              value: this.name,
            },
            // dedicatoria: {
            // 	code: "dedicatoria",
            // 	property_id: this.studentCredential.user.name,
            // 	value: "",
            // },
            mes: {
              property_id: 38,
              value: self.getMonthByNumber(self.selectedMonth),
              code: "mes",
            },
            numero: {
              property_id: 29,
              value: this.selectedDay,
              code: "numero",
            },
          },
        },
        uuid: this.studentCredential.uuid,
      };

      axios
        .post(
          "https://builder.dentrodahistoria.com.br/api/customs_characters/changePropertiesDynamic",
          { custom_character: custom_character }
        )
        .then(function (response) {
          self.$store.commit("UPDATE_STUDENT_UUID", response.data.custom.uuid);

          self.$router.push({ name: "student-step-2" });
        })
        .catch(function (error) {
          let fieldError = {
            field: "error",
            msg: error.response.data.message,
            rule: "error", // optional
          };
          self.$validator.errors.add(fieldError);
          self.callingLayout = false;
        });
    },

    hidePopUp() {
      this.showPopUp = false;
    },

    changeGender(gender) {
      const self = this;

      this.gender = gender.value; //this.studentCredential.main_product.gender[gender]

      self.genderType = Object.keys(self.genders).find(
        (key) => self.genders[key] === self.gender
      );
    },

    changeProduct(event) {
      const self = this;

      const productId = event.target.value;
      console.log(productId);

      // Find the product with the matching ID
      const productSelected = this.studentCredential.multiple_products.find(
        (item) => item.id == productId
      );

      if (productSelected) {
        // Assign product properties
        this.product = {
          builderProductId: productSelected.builder_id,
          productId: productId,
          gender: productSelected.gender,
          layouts: productSelected.layouts,
          layout_title: productSelected.layout_title,
          characterId: productSelected.character_id,
        };

        // Determine gender string based on main product types
        // const genderString = Object.keys(
        //   this.studentCredential.main_product.types
        // ).find(
        //   (key) =>
        //     this.studentCredential.main_product.types[key] ===
        //     productSelected.gender
        // );

        // Update genders with the found gender string
        //this.genders = this.product.gender;

        //self.setGender();
      } else {
        // Handle case where no product is found for the given ID
        console.error("Product not found for ID:", productId);
      }
    },

    validateDays(payload) {
      self = this;

      let month = payload.month ? payload.month : this.selectedBirthMonth;
      const year = payload.year ? payload.year : this.selectedBirthYear;

      if (!month) month = 13;

      let daysMonth;
      const newDays = [];

      if (
        month === 1 ||
        month === 3 ||
        month === 5 ||
        month === 7 ||
        month === 8 ||
        month === 10 ||
        month === 12 ||
        month === 13
      ) {
        daysMonth = 31;
      } else if (month === 4 || month === 6 || month === 9 || month === 11) {
        daysMonth = 30;
      } else {
        if ((year % 4 === 0 && year % 100 > 0) || year % 400 === 0) {
          daysMonth = 29;
        } else {
          daysMonth = 28;
        }
      }

      if (!month && !year) daysMonth = 31;

      for (let day = 1; day <= daysMonth; day++)
        newDays.push({ key: day, value: day });

      self.days = newDays;
      Vue.set(self.days, newDays);
    },

    changeDay(event) {
      // get data for this ID
      // let productSelected = this.studentCredential.multiple_products.reduce(
      // 	(all, item) => {
      // 		if (item.id == event.target.value) {
      // 			all = item;
      // 		}
      // 		return all;
      // 	},
      // 	{}
      // );
      // this.product = {
      // 	builderProductId: productSelected.builder_id,
      // 	productId: event.target.value,
      // 	gender: productSelected.gender,
      // 	layouts: productSelected.layouts,
      // 	layout_title: productSelected.layout_title,
      // 	characterId: productSelected.character_id,
      // };
      const self = this;
      self.selectedDay = event.target.value;
    },

    getMonthByNumber(currentMonth) {
      const self = this;

      var month;

      self.months.forEach((element) => {
        if (element.key == currentMonth) {
          month = element.name;
          //self.$store.commit("UPDATE_MAIN_PRODUCT_CUSTOM_MES", element.name);
        }
      });

      return month;
    },

    changeMonth(event) {
      const self = this;
      self.selectedMonth = event.target.value;

      const month = parseInt(event.target.value)
        ? parseInt(event.target.value)
        : 13;

      this.validateDays({ month: month });
    },

    async sendChild() {
      const self = this;

      this.$validator.errors.clear();

      if (self.multiple_products != false && self.product == null) {
        let fieldError = {
          field: "product",
          msg: "Preencha o produto corretamente",
          rule: "error", // optional
        };
        self.$validator.errors.add(fieldError);
        return;
      }

      if (self.name.length == 0) {
        let fieldError = {
          field: "username",
          msg: "Preencha o nome corretamente",
          rule: "error", // optional
        };
        self.$validator.errors.add(fieldError);
        return;
      }

      if (!self.gender) {
        let fieldError = {
          field: "gender",
          msg: "Preencha o gênero da criança",
          rule: "error", // optional
        };
        self.$validator.errors.add(fieldError);
        return;
      }

      this.$validator.validateAll().then((result) => {
        let loadAvatar = false;

        let genderOld = self.studentCredential.user.gender;
        let genderNew = self.gender;

        let characterBirthdate =
          self.studentCredential.user.birthdate !== null
            ? self.studentCredential.user.birthdate
            : self.studentCredential.user.created_at;

        // Explicação a multiple products, pode voltar um booleano ou um array, por isso deverá ser diferente de falso.
        if (result) {
          if (
            self.studentCredential.multiple_products &&
            self.studentCredential.multiple_products != false
          ) {
            var currentProduct = null;

            // Verify multiple products
            self.studentCredential.multiple_products.forEach((product) => {
              if (product.id == self.product.productId) {
                currentProduct = product;
              }
            });

            if (currentProduct) {
              self.$store.commit(
                "UPDATE_STUDENT_OLD_UUID",
                self.studentCredential.uuid
              );
              if (self.studentCredential.uuid) {
                self.$store.commit("UPDATE_STUDENT_IS_UPDTADING", true);
              }

              self.$store.commit("UPDATE_STUDENT_UUID", null);
              self.$store.commit("UPDATE_MAIN_PRODUCT", currentProduct);
            }
          }

          // Verify birthday
          if (
            self.studentCredential.main_product.brand.name.includes(
              "Aniversário"
            )
          ) {
            self.$store.commit(
              "UPDATE_MAIN_PRODUCT_CUSTOM_NUMERO",
              self.selectedDay
            );

            self.$store.commit(
              "UPDATE_MAIN_PRODUCT_CUSTOM_MES",
              self.selectedMonth
            );
          }

          // Verify Gender UUID
          if (genderOld === genderNew) {
            loadAvatar = true;
          } else {
            self.$store.commit("UPDATE_STUDENT_UUID", null);
          }

          self.$store.commit(
            "UPDATE_STUDENT_CREDENTIAL_CUSTOM_NAME",
            self.name
          );

          self.$store.commit("UPDATE_STUDENT_GENDER", self.gender);

          if (
            self.studentCredential.uuid &&
            self.studentCredential.main_product.brand.name.includes(
              "Aniversário"
            )
          ) {
            self.updateCustomCharacter();
          } else {
            self.$router.push({ name: "student-step-2" });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
.close-student {
  width: 60px;
  height: 60px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  text-align: center;
  line-height: 0px;
  border-radius: 100px;
  padding-top: 7px;
  position: absolute;
  top: 20px;
  left: 20px;

  span {
    font-family: Nunito;
    font-size: 7px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.71;
    letter-spacing: 0.14px;
    text-align: center;
    color: #afa2ba;
    position: relative;
    top: -7px;
  }
}

.input-group {
  position: relative;
  font-size: 16px;
}

.flag-relative {
  position: relative;
}

.flag-info {
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.3);
  background-color: #4567ba;
  max-width: 30%;
  padding: 0%;
  width: 100%;
  position: absolute;
  color: #cbe1ff;
  font-size: 14px;
  font-family: "Nunito";
  border-radius: 10px;
  display: block;
  text-align: left;
  font-weight: 200;
  right: -30%;
  bottom: 0px;
  top: -10%;

  p {
    margin-bottom: 0px !important;
    font-size: 14px;
  }

  .icon {
    align-items: center;
    display: flex;
  }

  b {
    color: #cbe1ff !important;
    font-size: 14px;
  }

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    top: 40%;
    width: 0px;
    height: 0px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #4567ba;
    left: -10px;
  }
}

.gender-list-item {
  font-size: inherit;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  height: 135px;
  justify-content: center;
  text-align: center;
  border-radius: 10px;
  color: #68596e;

  &.active {
    color: #1ad3d9 !important;
    position: relative;
    box-shadow: 0 0 1px 1px #2dd4d9;
    border-color: #2dd4d9;
    background-color: #eff;

    svg {
      fill: #1ad3d9 !important;
      stroke: #1ad3d9 !important;
    }
  }
}
.gender-list-item-label {
  display: block;
  text-align: center;
  font-size: 16px;
  position: relative;
  top: -0.47619rem;
}
.gender-list-separator {
  flex: 0 0 1.62857rem;
  font-size: 14px;
  line-height: 1.64;
  text-align: center;
  color: #afa2ba;
  top: 50%;
}
.gender-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.select-group {
  width: 100%;
}
label {
  font-weight: 400;
}
.gender-select {
  svg {
    max-width: 76px;
    width: 100%;
  }
}
.select {
  max-width: 100%;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  -webkit-appearance: none;
  touch-action: manipulation;
  margin-bottom: 0;
  z-index: 1;
  transition: all 150ms ease;
  padding: 4px 4px;
  display: block;
  width: 100%;
  background-color: transparent;
  height: 65px;
  border-radius: 5px;
  border: solid 1px #dfdae4;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  padding-left: 20px;
  width: 100%;
}
.recover-link {
  position: absolute;
  right: 20px;
  top: 35px;
  width: 150px;

  .btn {
    width: 100%;
  }
}
.form-student {
  font-family: "Nunito";
  width: 100%;
  max-width: 640px;
  text-align: center;
  margin: 0 auto;

  @include media-breakpoint-down(md) {
    width: 100%;
    padding-right: 2rem;
    padding-left: 2rem;
    .down-container {
      display: none;
    }
  }

  small {
    font-size: 12px !important;
  }

  h3 {
    text-align: center;
    font-size: 50px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: 1.33px;
    text-align: center;
    color: #68596e;
    font-weight: 800;
    margin-bottom: 10px;
    height: auto;
  }

  h5 {
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #afa2ba;
    margin-bottom: 30px;
  }

  .btn-primary {
    width: 100%;
    //font-family: Nunito;
    font-size: 14px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 4;
    letter-spacing: 0.8px;
    text-align: center;
    color: #ffffff;
    border-radius: 40px;
    text-transform: uppercase;
    margin-top: 30px;
  }
  .down-container {
    margin-top: 3.125rem;
  }
}

.student-name {
  span {
    color: #afa2ba;
  }
}

label {
  font-size: 16px;
}

.label-child {
  font-size: 12px !important;
}

.error {
  width: 100%;
  font-size: 14px;
  text-align: center;
  color: red;
}
</style>
